<template>
    <div>
        <vx-card title="Invite User" title-color="black" noShadow noRound>
            <vs-row>
                <vs-col vs-xs="12" vs-sm="6" vs-lg="6" class="mb-3">
                    <vs-input
                        v-model="data.email"
                        placeholder="eg: example@gmail.com"
                        label="Email"
                        class="w-full"
                        type="Email"
                        :disabled="submit_disabled"
                    />
                    <small class="block text-danger mt-1" v-if="api_errors.email">
                        {{ api_errors.email.join(', ') }}
                    </small>
                </vs-col>
                <vs-col vs-xs="12" vs-sm="6" vs-lg="6" class="mb-3">
                    <InputSelect 
                        :model.sync="data.role" 
                        input_class="w-full bg-white"
                        placeholder="Choose..."
                        label="Role"
                        :options="role_options"
                        :disabled="submit_disabled"
                    />
                    <small class="block text-danger mt-1" v-if="api_errors.role">
                        {{ api_errors.role.join(', ') }}
                    </small>
                </vs-col>
            </vs-row>

        </vx-card>
        <vs-row class="mt-base">
            <vs-col class="text-center">
                <vs-button class="w-full" @click="save" :disabled="submit_disabled">
                    Save
                </vs-button>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>
import { Request } from '../../utils/requests';

export default {
    data() {
        return  {
            data: {
                email: null,
                role: null
            },

            role_options: [
                {label: 'Admin', value: 'a'},
                {label: 'Staff', value: 's'},
                {label: 'Client', value: 'c'},
            ],

            submit_disabled: false,
            api_errors: {}
        }
    },
    methods: {
        save() {
            this.api_errors = {}
            this.submit_disabled = true
            this.$vs.loading()

            let request_method = "POST"
            let url = this.$store.state.apiendpoints.user_invite
            let payload = this.data
            
            Request(this, request_method, url, null, payload).then(
                res => {
                    this.submit_disabled = false
                    this.$vs.loading.close()
                    this.$router.push({name: 'user-invited-list'})
                    this.$vs.notify({
                        time: 3000,
                        title: 'Success',
                        text: "User Invitation request is palced successfully.",
                        iconPack: 'feather',
                        icon: 'icon-check-circle',
                        color: 'success'
                    })
                },
                err => {
                    this.submit_disabled = false
                    this.$vs.loading.close()
                    if (err.error) {
                        this.api_errors = err.error
                        this.$vs.notify({
                            time: 3000,
                            title: 'Error',
                            text: (err.error && err.error.__non_field_errors__) ? err.error.__non_field_errors__.join(',') : 'Please fix the errors and try again!',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    } else {
                        this.$vs.notify({
                            time: 3000,
                            title: 'Error',
                            text: "An unknown error occurred! Please try again!",
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }
                }
            )
        }
    }
}

</script>
